import React from 'react';
import { graphql } from 'gatsby';

import BaseLayout from '../../components/templates/BaseLayout';
import PrimaryHeader from '../../components/atoms/typography/PrimaryHeader';
import SubHeader from '../../components/atoms/typography/SubHeader';
import Headline from '../../components/atoms/typography/Headline';
import Spacer from '../../components/atoms/utility/Spacer';
import SEO from '../../components/atoms/utility/seo';
import JumboPanel from '../../components/atoms/JumboPanel';
import DownloadAppCTA from '../../components/atoms/callouts/DownloadAppCTA';

const marketplaceFeatures = {
  ['Compete.']: [
    {
      name: 'Be In The Know',
      description:
        'The flockx app is packed with features to help you stay connected to your community. So, if you’re looking for a way to stay in the know about Grand Rapids, flockx is the perfect app for you.'
    },
    {
      name: 'Tag Community Activity',
      description:
        'Found a cool spot? Spotted a great vibe? Tag and share them to your community for others to explore.'
    }
  ],
  ['Discover.']: [
    {
      name: 'Communities',
      description:
        'With flockx, you can stay informed of upcoming events, discover new places to explore and find live music in local restaurants, bars, and other venues. flockx makes it easy to explore your community and make the most of your time.'
    },
    {
      name: 'Unlock Hidden Gems',
      description:
        'Download flockx today to unlock the hidden gems in Grand Rapids that you may have never known about. The more you interact with your community the more vibes you unlock for yourself.'
    }
  ],
  ['Level Up.']: [
    {
      name: 'Community Reputation',
      description:
        'Share cool spots and the memories you’ve had with your communities to increase your clout.'
    },
    {
      name: 'Community Rating',
      description:
        'Rate your memories using ratings that matter to your community to help community members grow.'
    },
    {
      name: 'Community Support',
      description:
        'Help new community members learn about your community and where to get started.'
    }
  ]
};

export default function DiscoverGrandRapidsPage({
                                                  data: { heroImg, mobileHeroImg, buildImg, growImg, openGraphImg }
                                                }) {
  const jumboPanelOne = {
    image: {
      imageSrc: buildImg,
      imageAlt: 'friends high five while working outside'
    },
    headline: {},
    paragraph: {},
    list: {
      name: 'Find Your Vibe', //change name for section
      listContent: marketplaceFeatures['Compete.'],
      headerColor: '#7593FF'
    }
  };

  const jumboPanelTwo = {
    image: {
      imageSrc: growImg,
      imageAlt: 'vintage red lion sign in grand rapids'
    },
    headline: {},
    paragraph: {},
    list: {
      name: 'Explore Grand Rapids',
      listContent: marketplaceFeatures['Discover.'],
      headerColor: '#63D2E1'
    }
  };

  return (
    <BaseLayout
      heroContent={MarketplacePageHeroContent}
      heroImg={heroImg}
      heroAlt={'A beautiful scene of Grand Rapids.'}
      mobileHeroImg={mobileHeroImg}
    >
      <SEO
        title='Discover Grand Rapids'
        keywords={[
          'Grand Rapids',
          'community',
          'explore',
          'experience',
          'discover',
          'flockx',
          'local',
          'events',
          'hidden gems'
        ]}
        description={`Stay connected to the pulse of Grand Rapids. Download the flockx app today to find events and hotspots near you.`}
        customSeoImage={openGraphImg}
      />
      <Spacer />
      <Spacer className='block lg:hidden' />
      <JumboPanel content={jumboPanelOne} />
      <Spacer />
      <Spacer className='block md:hidden' />
      <JumboPanel content={jumboPanelTwo} imageSide='left' />
      <Spacer />
      <Spacer className='block lg:hidden' />
      <div className='mx-auto text-left sm:text-center max-w-3xl mb-4 lg:mb-12'>
        <Headline
          spanText='Ready for a new way to explore?'
          spanColor='#000'
          className='mx-auto text-center max-w-[350px] md:max-w-xl mb-4 lg:mb-12 capitalize'
        />
        <div className='relative z-10 text-center'>
          <DownloadAppCTA containerStyles='justify-center' />
        </div>
      </div>
      <Spacer />
      <Spacer className='block lg:hidden' />
    </BaseLayout>
  );
}

function MarketplacePageHeroContent() {
  return (
    <div className='-mt-[15%] sm:mt-0'>
      <PrimaryHeader
        headerText='Discover Grand Rapids'
        className=' mb-2 sm:mb-4 md:mb-8 lg:mb-8 text-white capitalize font-medium max-w-[390px] sm:max-w-none md:max-w-4xl lg:max-w-lg lg:mr-auto lg:ml-0'
      />
      <SubHeader
        headerText='Stay connected to the pulse of your local community'
        className='mb-4 md:mb-8 lg:mb-10 xl:mb-12 text-white font-light max-w-xs sm:max-w-sm lg:max-w-lg sm:mx-0'
      />
      <DownloadAppCTA containerStyles='justify-start' />
      <p className='block sm:pt-0 font-light sm:text-lg md:text-xl lg:text-2xl text-white'>
        Available in the US
      </p>
    </div>
  );
}

export const query = graphql`
    query DiscoverGrandRapidsPageQuery {
        heroImg: file(name: { eq: "grand-rapids-main-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        mobileHeroImg: file(name: { eq: "grand-rapids-mobile-hero" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
        buildImg: file(name: { eq: "build-platform-img" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        growImg: file(name: { eq: "grand-rapids-tile-2" }) {
            childImageSharp {
                gatsbyImageData(width: 889)
            }
        }
        openGraphImg: file(name: { eq: "grand-rapids-preview" }) {
            childImageSharp {
                gatsbyImageData
            }
        }
    }
`;
